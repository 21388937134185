const icons = {
  address: 'mdi-map-marker',
  advertisement: 'mdi-google-ads',
  advertisements: 'mdi-google-ads',
  advertisementType: 'mdi-format-list-text',
  answer: 'mdi-checkbox-marked',
  autoApply: 'mdi-tune',
  autoApplyDiscountPercentage: 'mdi-percent-outline',
  birthdate: 'mdi-cake',
  bookBeforeDays: 'mdi-calendar-clock',
  booking: 'mdi-calendar-check-outline',
  bookingCounter: 'mdi-numeric-1-box-outline',
  bookingDate: 'mdi-calendar-range',
  bookingNumber: 'mdi-numeric-2-box-outline',
  businessName: 'mdi-store',
  calendar: 'mdi-calendar-month-outline',
  capacity: 'mdi-account-switch',
  cities: 'mdi-map-marker-path',
  client: 'mdi-account-heart',
  code: 'mdi-barcode',
  licenseType: 'mdi-card-account-details-outline',
  comment: 'mdi-comment-processing-outline',
  commentedAt: 'mdi-calendar-clock',
  commentStatus: 'mdi-comment-alert-outline',
  licenseNumber: 'mdi-file-document-outline',
  cost: 'mdi-cash',
  countries: 'mdi-map',
  country: 'mdi-earth',
  paymentUrl: 'mdi-link-variant',
  coverImageUrl: 'mdi-image-outline',
  createdAt: 'mdi-calendar-clock',
  criteria: 'mdi-group',
  criteriaItem: 'mdi-check-circle-outline',
  currencyCode: 'mdi-cash',
  currencyName: 'mdi-cash',
  dashboard: 'mdi-monitor-dashboard',
  description: 'mdi-file-document-outline',
  discountCode: 'mdi-ticket-percent',
  discountCodes: 'mdi-percent-outline',
  discountCodeString: 'mdi-ticket-percent',
  discountPercentage: 'mdi-percent-outline',
  displayKey: 'mdi-key-variant',
  displayName: 'mdi-account-outline',
  durationType: 'mdi-clock-time-eight-outline',
  durationValue: 'mdi-timer-outline',
  email: 'mdi-email-outline',
  endDate: 'mdi-calendar-clock',
  eventType: 'mdi-shape-plus',
  fullName: 'mdi-account-outline',
  home: 'mdi-home',
  isoCode: 'mdi-earth',
  isServeMen: 'mdi-gender-male',
  isServeWomen: 'mdi-gender-female',
  latitude: 'mdi-map-marker',
  longitude: 'mdi-map-marker',
  message: 'mdi-message-text-outline',
  messageNumber: 'mdi-numeric-3-box-outline',
  messageType: 'mdi-message-alert-outline',
  money: 'mdi-cash-multiple',
  national: 'mdi-flag-variant',
  nationality: 'mdi-flag-variant',
  paidAmount: 'mdi-cash',
  password: 'mdi-lock-outline',
  payment: 'mdi-cash-multiple',
  paymentNumber: 'mdi-numeric-4-box-outline',
  percentage: 'mdi-percent-outline',
  phone: 'mdi-phone-outline',
  phoneCode: 'mdi-phone-outline',
  price: 'mdi-cash',
  pricePerDay: 'mdi-cash',
  provider: 'mdi-account-tie',
  quantity: 'mdi-numeric-5-box-outline',
  question: 'mdi-help',
  questionType: 'mdi-help-circle-outline',
  rate: 'mdi-star-half-full',
  rateCount: 'mdi-star-outline',
  reference: 'mdi-card-account-details-outline',
  removeCoverImage: 'mdi-image-off',
  response: 'mdi-message-reply',
  sender: 'mdi-account-outline',
  service: 'mdi-brush',
  serviceImage: 'mdi-image-outline',
  servicePackage: 'mdi-gift-outline',
  serviceType: 'mdi-format-list-bulleted-type',
  startDate: 'mdi-calendar-clock',
  subtitle: 'mdi-subtitles',
  supportMessage: 'mdi-face-agent',
  title: 'mdi-text-recognition',
  type: 'mdi-shape',
  typeKey: 'mdi-key',
  updatedAt: 'mdi-clock-time-eight-outline',
  url: 'mdi-link-variant',
  user: 'mdi-account',
  userEvent: 'mdi-party-popper',
  users: 'mdi-account-group',
  value: 'mdi-numeric-6-box-outline',
  transactionNumber: 'mdi-pound',
  paymentGateway: 'mdi-bank',
  status: 'mdi-check-circle',

  // sidebar
  DASHBOARD_GROUP: 'mdi-view-dashboard',
  PROFILE_GROUP: 'mdi-account-circle',
  USERS_GROUP: 'mdi-account-group',
  SETTINGS_GROUP: 'mdi-cog',
  PROVIDER_GROUP: 'mdi-store',
  CLIENT_GROUP: 'mdi-account-heart',
  SUPPORT_MESSAGES_GROUP: 'mdi-message-text',
};

export default icons;

export function getIcon(key: string) {
  if (key in icons) {
    return icons[key as keyof typeof icons];
  } else {
    return null;
  }
}
