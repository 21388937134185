import enStrings from '@/i18n/lang/currencies/en';

const arStrings: typeof enStrings = {
  CURRENCY_STRINGS: {
    AED: 'الدرهم الإماراتي',
    AFN: 'الأفغاني الأفغاني',
    ALL: 'ليك ألباني',
    AMD: 'الدرام الأرميني',
    ANG: 'جuilder الهولندي الأنتيلي',
    AOA: 'الكوانزا الأنغولي',
    ARS: 'البيزو الأرجنتيني',
    AUD: 'الدولار الأسترالي',
    AWG: 'فلورن أروبي',
    AZN: 'مانات أذربيجاني',
    BAM: 'المارك القابل للتحويل البوسني الهرسكي',
    BBD: 'الدولار البربادوسي',
    BDT: 'تاكا بنغلاديشي',
    BGN: 'ليف بلغاري',
    BHD: 'الدينار البحريني',
    BIF: 'فرنك بروندي',
    BMD: 'الدولار البرمودي',
    BND: 'الدولار البروناي',
    BOB: 'بوليفيانو بوليفي',
    BRL: 'الريال البرازيلي',
    BSD: 'الدولار الباهامي',
    BTN: 'النولتروم البوتاني',
    BWP: 'بولا بتسواني',
    BYN: 'الروبل البيلاروسي',
    BZD: 'الدولار البليزي',
    CAD: 'الدولار الكندي',
    CDF: 'فرنك كونغولي',
    CHF: 'الفرنك السويسري',
    CLP: 'البيزو التشيلي',
    CNY: 'اليوان الصيني',
    CRC: 'كولون كوستاريكي',
    CUP: 'البيزو الكوبي',
    CVE: 'الاسكودو الرأس الأخضر',
    CZK: 'الكرونا التشيكية',
    DJF: 'فرنك جيبوتي',
    DKK: 'الكرون الدنماركي',
    DZD: 'الدينار الجزائري',
    ERN: 'الناكفا الإريترية',
    ETB: 'البير الإثيوبي',
    EUR: 'اليورو',
    FJD: 'الدولار الفيجي',
    FKP: 'جنيه جزر فوكلاند',
    GBP: 'الجنيه البريطاني',
    GEL: 'اللاري الجورجي',
    GHS: 'السيدي الغاني',
    GIP: 'جنيه جبل طارق',
    GMD: 'دلاسي الغامبي',
    GYD: 'الدولار الغياني',
    HKD: 'الدولار الهونغ كونغي',
    HNL: 'اللمبيرا الهندوراسية',
    HRK: 'الكونا الكرواتية',
    HTG: 'جوردى هايتي',
    HUF: 'الفورنت المجري',
    IDR: 'الروبية الإندونيسية',
    INR: 'الروبية الهندية',
    IQD: 'الدينار العراقي',
    IRR: 'الريال الإيراني',
    ISK: 'الكرونا الأيسلندية',
    JMD: 'الدولار الجامايكي',
    JOD: 'الدينار الأردني',
    JPY: 'الين الياباني',
    KES: 'الشلن الكيني',
    KGS: 'السوم القيرغيزستاني',
    KHR: 'الريال الكمبودي',
    KMF: 'فرنك جزر القمر',
    KPW: 'وون كوريا الشمالية',
    KRW: 'وون كوريا الجنوبية',
    KWD: 'الدينار الكويتي',
    KYD: 'الدولار جزر كايمان',
    KZT: 'التينغي كازاخستاني',
    LAK: 'الكيب اللاوسي',
    LBP: 'الجنيه اللبناني',
    LKR: 'الروبية السريلانكية',
    LRD: 'الدولار الليبيري',
    LSL: 'لوتي الليسوتو',
    LYD: 'الدينار الليبي',
    MAD: 'الدرهم المغربي',
    MGA: 'الأرياري المدغشقري',
    MKD: 'الدينار المقدوني',
    MMK: 'الكيات الميانمارية',
    MOP: 'باتاكا ماكاوي',
    MRU: 'الأوقية الموريتانية',
    MUR: 'الروبية الموريشية',
    MVR: 'الرفيع المالديفي',
    MWK: 'الكواشا الملاوية',
    MYR: 'الرينغيت الماليزي',
    MZN: 'المتيكال الموزمبيقي',
    NAD: 'الدولار الناميبي',
    NGN: 'النايرا النيجيري',
    NIO: 'الكوردوبا النيكاراغوي',
    NOK: 'الكرونة النرويجية',
    NPR: 'الروبية النيبالية',
    NZD: 'الدولار النيوزيلندي',
    OMR: 'الريال العماني',
    PAB: 'البالبوا البنمي',
    PEN: 'السول البيروفي',
    PGK: 'الكينا البابوا غينيا الجديدة',
    PHP: 'البيزو الفلبيني',
    PKR: 'الروبية الباكستانية',
    PLN: 'الزلوتي البولندي',
    PYG: 'الغواراني الباراغواي',
    QAR: 'الريال القطري',
    RON: 'الليو الروماني',
    RSD: 'الدينار الصربي',
    RUB: 'الروبل الروسي',
    RWF: 'الفرنك الرواندي',
    SAR: 'الريال السعودي',
    SBD: 'الدولار جزر سليمان',
    SCR: 'الروبية السيشيلية',
    SDG: 'الجنيه السوداني',
    SEK: 'الكرونة السويدية',
    SGD: 'الدولار السنغافوري',
    SHP: 'جنيه سانت هيلانة',
    SLL: 'الليون السيراليوني',
    SOS: 'الشلن الصومالي',
    SRD: 'الدولار السورينامي',
    SSP: 'الجنيه الجنوب السوداني',
    STN: 'دوبرا ساو تومي وبرينسيبي',
    SYP: 'الجنيه السوري',
    SZL: 'ليلانجيني سوازيلندي',
    THB: 'الباخت التايلاندي',
    TJS: 'السوموني التاجيكستاني',
    TMT: 'المانات التركمانستاني',
    TND: 'الدينار التونسي',
    TOP: 'بانغا تونغا',
    TRY: 'الليرة التركية',
    TTD: 'الدولار الثنائي والثلاثي الأبعاد',
    TWD: 'الدولار التايواني',
    TZS: 'شلن تنزاني',
    UAH: 'هريفنيا أوكرانية',
    UGX: 'شلن أوغندي',
    USD: 'دولار أمريكي',
    UYU: 'بيزو أوروغواي',
    UZS: 'سوم أوزبكستاني',
    VES: 'بوليفار فنزويلي',
    VND: 'دونج فيتنامي',
    VUV: 'فاتو فانواتو',
    WST: 'تالا ساموا',
    XAF: 'فرنك وسط أفريقي',
    XCD: 'دولار شرق الكاريبي',
    XOF: 'فرنك غرب أفريقي',
    XPF: 'فرنك سي إف بي',
    YER: 'ريال يمني',
    ZAR: 'راند جنوب أفريقيا',
    ZMW: 'كواشا زامبيا',
    ZWL: 'دولار زيمبابوي',
  },
};

export default arStrings;
