import { ref, reactive, isRef, readonly } from 'vue';
import { useVuelidate } from '@vuelidate/core';

import { AxiosError } from '@/http';
import { $t } from '@/i18n';

type AlertType = {
  show: boolean;
  type?: 'error' | 'success' | 'warning' | 'info';
  text?: string;
};

export default function useForm(
  rules: Record<string, unknown>,
  initialData: Record<string, unknown> | never,
  submit: () => void
) {
  // inputs
  const _rules: Record<string, unknown> = rules;
  const _data = reactive({ ...initialData });
  const _submit = submit;

  // constants
  const _loading = ref<boolean>(false);
  const _alert = reactive<AlertType>({
    show: false,
    type: undefined,
    text: undefined,
  });
  const _internalErrors = reactive<Record<string, string[]>>({});
  const _externalErrors = reactive<Record<string, string[]>>({});

  // validator
  const _validator = useVuelidate(_rules, _data, {
    $lazy: true,
    $autoDirty: false,
    $externalResults: _externalErrors,
  });

  // validate
  async function validate(): Promise<boolean> {
    _validator.value.$clearExternalResults();
    const res = await _validator.value.$validate();
    clearInternalErrors();

    _validator.value.$errors.forEach(($error) => {
      if (!Object.keys(_internalErrors).includes($error.$property)) {
        _internalErrors[$error.$property] = [];
      }
      _internalErrors[$error.$property].push(
        isRef($error.$message) ? $error.$message.value : $error.$message
      );
    });

    return res;
  }

  // reset form
  function reset(): void {
    _validator.value.$reset();
    Object.keys(initialData).forEach((key) => {
      _data[key] = initialData[key];
    });
  }

  // alert
  function showAlert(
    text: string,
    type: 'error' | 'success' | 'warning' | 'info',
    timeout?: number
  ): void {
    _alert.text = text;
    _alert.type = type;
    _alert.show = true;

    if (timeout !== undefined) {
      setTimeout(clearAlert, 3000);
    }
  }

  function clearAlert(): void {
    delete _alert.text;
    _alert.type = undefined;
    _alert.show = false;
  }

  // handling error
  function handleError(error: unknown): void {
    let message: string;
    if (error instanceof AxiosError && error.response?.data.message) {
      message = error.response.data.message;
      setExternalErrors(error.response.data.errors);
    } else {
      console.log(error);
      message = $t('ERROR.AN_ERROR_OCCURED_PLEASE_TRY_AGAIN_LATER');
    }
    showAlert(message, 'error');
  }

  function setExternalErrors(errors: Record<string, string[]>): void {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (!Object.keys(_internalErrors).includes(key)) {
          _internalErrors[key] = [];
        }
        _internalErrors[key].push(...errors[key]);
      });

      clearExternalErrors();
      Object.assign(_externalErrors, errors);
    }
  }

  // clear errors
  function clearErrors(): void {
    clearAlert();
    clearInternalErrors();
    clearExternalErrors();
  }

  function clearInternalErrors(): void {
    Object.keys(_internalErrors).forEach((key) => {
      delete _internalErrors[key];
    });
  }

  function clearExternalErrors(): void {
    Object.keys(_externalErrors).forEach((key) => {
      delete _externalErrors[key];
    });
  }

  return {
    rules: _rules,
    data: _data,
    loading: _loading,
    alert: _alert,
    errors: _internalErrors,
    externalErrors: readonly(_externalErrors),
    validator: _validator,
    submit: _submit,
    validate,
    reset,
    showAlert,
    clearAlert,
    handleError,
    setExternalErrors,
    clearErrors,
  };
}
