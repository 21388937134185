function formatDate(
  inputDateString?: string | null,
  defaultValue?: string
): string | undefined {
  if (!inputDateString) {
    return defaultValue;
  }

  // Parse the input string into a Date object
  const parsedDate: Date = new Date(inputDateString);

  const formattedDate: string = parsedDate.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: undefined,
    minute: undefined,
    second: undefined,
  });

  return formattedDate;
}

function formatDateTime(
  inputDateTimeString?: string | null,
  defaultValue?: string
): string | undefined {
  if (!inputDateTimeString) {
    return defaultValue;
  }

  // Parse the input string into a Date object
  const parsedDateTime: Date = new Date(inputDateTimeString);

  const formattedDate: string = parsedDateTime.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return formattedDate;
}

function formatNumber(
  number?: number | null,
  nDigits?: number,
  defaultValue?: string
): string | null | undefined {
  if (!number) {
    return defaultValue;
  }

  return number.toFixed(nDigits);
}

export default {
  date: formatDate,
  dateTime: formatDateTime,
  number: formatNumber,
};
