import { createApp } from 'vue';

import router from '@/router';
import store from '@/stores';
import vuetify from '@/plugins/vuetify';
import i18n from '@/i18n';
import App from '@/App.vue';
import { loadFonts } from '@/plugins/webfontloader';

// components
import DashboardLayout from '@/components/DashboardLayout.vue';
import AuthLayout from '@/components/AuthLayout.vue';
import PeripheralsSelect from '@/components/PeripheralsSelect.vue';

loadFonts();

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(vuetify);

app.component('DashboardLayout', DashboardLayout);
app.component('AuthLayout', AuthLayout);
app.component('PeripheralsSelect', PeripheralsSelect);

router
  .isReady()
  .then(() => {
    app.mount('#app');
  })
  .catch((e) => console.error(e));
