import { type RouteRecordRaw } from 'vue-router';

// Advertisements
import AdvertisementForm from '@/views/advertisements/AdvertisementForm.vue';
import AdvertisementsPage from '@/views/advertisements/AdvertisementsPage.vue';

// Discount Codes
import DiscountCodeForm from '@/views/discountCodes/DiscountCodeForm.vue';
import DiscountCodesPage from '@/views/discountCodes/DiscountCodesPage.vue';

// Services
import ServiceForm from '@/views/services/ServiceForm.vue';
import ServicesPage from '@/views/services/ServicesPage.vue';

// Service Packages
import ServicePackageForm from '@/views/servicePackages/ServicePackageForm.vue';

const routes: RouteRecordRaw[] = [
  // advertisements -----------------------------------------------------------------
  {
    path: '/advertisements',
    name: 'advertisements',
    meta: { userTypes: ['superadmin', 'provider'] },
    redirect: () => ({ name: 'advertisements.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|requested|accepted|rejected|canceled)',
        component: AdvertisementsPage,
        name: 'advertisements.tab',
        meta: { userTypes: ['superadmin', 'provider'] },
      },
      {
        path: ':id(\\d+)',
        component: AdvertisementForm,
        name: 'advertisement',
        meta: { mode: 'view', userTypes: ['superadmin', 'provider'] },
      },
      {
        path: 'create',
        component: AdvertisementForm,
        name: 'advertisementCreate',
        meta: { mode: 'create', userTypes: ['superadmin', 'provider'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: AdvertisementForm,
        name: 'advertisementUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'provider'] },
      },
    ],
  },

  // discount-codes -----------------------------------------------------------------
  {
    path: '/discount-codes',
    name: 'discountCodes',
    meta: { userTypes: ['superadmin', 'provider'] },
    redirect: () => ({ name: 'discountCodes.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|active|inactive)',
        component: DiscountCodesPage,
        name: 'discountCodes.tab',
        meta: { userTypes: ['superadmin', 'provider'] },
      },
      {
        path: ':id(\\d+)',
        component: DiscountCodeForm,
        name: 'discountCode',
        meta: { mode: 'view', userTypes: ['superadmin', 'provider'] },
      },
      {
        path: 'create',
        component: DiscountCodeForm,
        name: 'discountCodeCreate',
        meta: { mode: 'create', userTypes: ['provider'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: DiscountCodeForm,
        name: 'discountCodeUpdate',
        meta: { mode: 'edit', userTypes: ['provider'] },
      },
    ],
  },

  // service-packages -----------------------------------------------------------------
  {
    path: '/services/:serviceId(\\d+)/service-packages',
    children: [
      {
        path: ':id(\\d+)',
        component: ServicePackageForm,
        name: 'servicePackage',
        meta: { mode: 'view', userTypes: ['superadmin', 'provider'] },
      },
      {
        path: 'create',
        component: ServicePackageForm,
        name: 'servicePackageCreate',
        meta: { mode: 'create', userTypes: ['provider'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: ServicePackageForm,
        name: 'servicePackageUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'provider'] },
      },
    ],
  },

  // services -----------------------------------------------------------------
  {
    path: '/services',
    name: 'services',
    meta: { userTypes: ['superadmin', 'provider'] },
    redirect: () => ({ name: 'services.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|accepted|rejected|pending|hidden)',
        component: ServicesPage,
        name: 'services.tab',
        meta: { userTypes: ['superadmin', 'provider'] },
      },
      {
        path: ':id(\\d+)',
        component: ServiceForm,
        name: 'service',
        meta: { mode: 'view', userTypes: ['superadmin', 'provider'] },
      },
      {
        path: 'create',
        component: ServiceForm,
        name: 'serviceCreate',
        meta: { mode: 'create', userTypes: ['provider'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: ServiceForm,
        name: 'serviceUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'provider'] },
      },
    ],
  },
];

export default routes;
