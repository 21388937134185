import { nextTick } from 'vue';
import { createI18n, useI18n } from 'vue-i18n';
import { LocaleInstance } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';

import { backendApi } from '@/http';
import en from '@/i18n/lang/en';
import ar from '@/i18n/lang/ar';

const supportedLanguages = ['en', 'ar'] as const;
type SupportedLanguage = (typeof supportedLanguages)[number];

const RTL_LANGUAGES: SupportedLanguage[] = ['ar'];
const FALLBACK_LANGUAGE: SupportedLanguage = 'ar';

const loadedLanguages: SupportedLanguage[] = ['en', 'ar'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const i18n = new (createI18n as any)({
  legacy: false, // vuetify does not support the legacy mode of vue-i18n
  locale: FALLBACK_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  missingWarn: false,
  messages: { en: en, ar: ar }, // pre-load frequently used languages
  pluralRules: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ar: function (choice: number, choicesLength: number): number {
      let index = 0;

      if (choice === 1) {
        index = 0;
      } else if (choice === 2) {
        index = 1;
      } else if (choice < 11) {
        index = 2;
      } else {
        index = 3;
      }

      return index < choicesLength ? index : choicesLength - 1;
    },
  },
});
const vueI18nAdapter: LocaleInstance = createVueI18nAdapter({ i18n, useI18n });

async function saveAndSetLang(
  lang: SupportedLanguage
): Promise<SupportedLanguage> {
  setAppLang(lang);
  return lang;
}

function setAppLang(lang: SupportedLanguage): void {
  const rtl = RTL_LANGUAGES.includes(lang) ? true : false;

  i18n.global.locale.value = lang;

  backendApi.defaults.headers.common['Accept-Language'] = lang;

  document.querySelector('html')?.setAttribute('dir', rtl ? 'rtl' : 'ltr');
  document.querySelector('html')?.setAttribute('lang', lang);

  // location.reload(); // maybe required for switing between ltr & rtl
}

async function loadAndSetAppLang(lang: string): Promise<void> {
  // language not supported
  if (!supportedLanguages.find((validLang) => validLang === lang)) {
    console.error(`Unsupported Language "${lang}"`);
    await saveAndSetLang(FALLBACK_LANGUAGE);
    return nextTick();
  }

  // the same language
  if (
    i18n.global.locale.value === lang &&
    i18n.global.availableLocales.includes(lang)
  ) {
    return nextTick();
  }

  // the language was already loaded
  if (loadedLanguages.includes(lang as SupportedLanguage)) {
    await saveAndSetLang(lang as SupportedLanguage);
    return nextTick();
  }

  // the language hasn't been loaded yet
  const messages = await import(
    /* webpackChunkName: "lang-[request]" */ `@/i18n/lang/${lang}.ts`
  );

  loadedLanguages.push(lang as SupportedLanguage);
  i18n.global.setLocaleMessage(lang, messages.default);
  await saveAndSetLang(lang as SupportedLanguage);

  return nextTick();
}

const $t = i18n.global.t;

export default i18n;
export {
  type SupportedLanguage,
  FALLBACK_LANGUAGE,
  RTL_LANGUAGES,
  vueI18nAdapter,
  loadAndSetAppLang,
  $t,
};
