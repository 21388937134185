<template>
  <AuthLayout width-class="w-75">
    <template #content>
      <v-row>
        <v-col cols="12" md="6" dir="ltr">
          <p class="text-h6 mb-4">
            Terms and Conditions for Lets Event Application and Dashboard
          </p>

          <p class="text-subtitle-2 font-weight-bold">1. Acceptance of Terms</p>
          <p class="text-caption ps-4">
            By downloading, installing, and/or using Lets Event Application and
            Dashboard, you agree to comply with and be bound by these Terms and
            Conditions ("Terms"). If you do not agree to these Terms, please do
            not use the App & Dashboard.
          </p>

          <p class="text-subtitle-2 font-weight-bold">
            2. Description of Services
          </p>
          <p class="text-caption ps-4">
            The App provides users with a list of products and services to
            arrange events such as weddings, family parties, and other
            occasions. Users can select services and send requests to service
            providers through the App.
          </p>

          <p class="text-subtitle-2 font-weight-bold">3. User Accounts</p>
          <p class="text-caption ps-4">
            To use the App & Dashboard, you may need to create an account. You
            agree to:
          </p>
          <v-list>
            <li class="text-caption ps-4">
              Provide accurate, current, and complete information during the
              registration process.
            </li>
            <li class="text-caption ps-4">
              Maintain and promptly update your account information.
            </li>
            <li class="text-caption ps-4">
              Keep your login credentials secure and confidential.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">4. Service Requests</p>
          <v-list>
            <li class="text-caption ps-4">
              Users can browse and select services listed on the App.
            </li>
            <li class="text-caption ps-4">
              Upon selection, users can send a request to the service provider.
            </li>
            <li class="text-caption ps-4">
              The service provider will review the request and either accept or
              reject it.
            </li>
            <li class="text-caption ps-4">
              Users can check the service provider’s decision on the App.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">
            5. Payments and Invoices
          </p>
          <v-list>
            <li class="text-caption ps-4">
              No payments are made through the App.
            </li>
            <li class="text-caption ps-4">
              Service providers will issue payment invoices to users through the
              dashboard.
            </li>
            <li class="text-caption ps-4">
              Users will receive invoices via email or other communication
              methods provided.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">6. Responsibilities</p>
          <p class="text-caption ps-4"><strong>For Users:</strong></p>
          <v-list>
            <li class="text-caption ps-4">
              Ensure that all information provided to service providers is
              accurate and complete.
            </li>
            <li class="text-caption ps-4">
              Communicate directly with service providers regarding service
              details and payment arrangements.
            </li>
          </v-list>
          <p class="text-caption ps-4">
            <strong>For Service Providers:</strong>
          </p>
          <v-list>
            <li class="text-caption ps-4">
              Respond to service requests in a timely manner.
            </li>
            <li class="text-caption ps-4">
              Issue payment invoices to users accurately and promptly.
            </li>
            <li class="text-caption ps-4">
              Deliver the agreed-upon services to users.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">
            7. Limitation of Liability
          </p>
          <v-list>
            <li class="text-caption ps-4">
              The App acts solely as a platform to connect users with service
              providers.
            </li>
            <li class="text-caption ps-4">
              The App is not responsible for the quality, safety, legality, or
              delivery of the services provided.
            </li>
            <li class="text-caption ps-4">
              The App is not responsible for any disputes or issues arising
              between users and service providers, including but not limited to
              payments and service fulfillment.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">8. Changes to Terms</p>
          <p class="text-caption ps-4">
            We reserve the right to modify these Terms at any time. We will
            notify users of significant changes through the App or other
            communication methods. Continued use of the App or Dashboard after
            changes have been made constitutes acceptance of the updated Terms.
          </p>

          <p class="text-subtitle-2 font-weight-bold">9. Termination</p>
          <p class="text-caption ps-4">
            We may terminate or suspend access to the App or Dashboard
            immediately, without prior notice or liability, for any reason,
            including but not limited to breach of these Terms.
          </p>

          <p class="text-subtitle-2 font-weight-bold">10. Governing Law</p>
          <p class="text-caption ps-4">
            These Terms are governed by and construed in accordance with the
            laws of Kingdom of Saudi Arabia. Any legal action or proceeding
            arising under these Terms will be brought exclusively in the courts
            located in Kingdom of Saudi Arabia.
          </p>

          <p class="text-subtitle-2 font-weight-bold">11. Contact Us</p>
          <p class="text-caption ps-4">
            If you have any questions or concerns about these Terms, please
            contact us at
            <a href="mailto:support@letsevent.net">support@letsevent.net</a>.
          </p>

          <p class="text-caption ps-4">
            By using Lets Event Application and Dashboard, you acknowledge that
            you have read, understood, and agree to be bound by these Terms and
            Conditions.
          </p>
        </v-col>
        <v-col cols="12" md="6" dir="rtl">
          <p class="text-h6 mb-4">
            البنود والشروط لتطبيق ولوحة تحكم Lets Event
          </p>

          <p class="text-subtitle-2 font-weight-bold">1. قبول البنود</p>
          <p class="text-caption ps-4">
            من خلال تحميل وتثبيت و/أو استخدام تطبيق ولوحة تحكم Lets Event، فإنك
            توافق على الامتثال لهذه البنود والشروط ("البنود"). إذا لم توافق على
            هذه البنود، يرجى عدم استخدام التطبيق ولوحة التحكم.
          </p>

          <p class="text-subtitle-2 font-weight-bold">2. وصف الخدمات</p>
          <p class="text-caption ps-4">
            يوفر التطبيق للمستخدمين قائمة بالمنتجات والخدمات لتنظيم الأحداث مثل
            الأعراس والحفلات العائلية والمناسبات الأخرى. يمكن للمستخدمين اختيار
            الخدمات وإرسال الطلبات إلى مقدمي الخدمات من خلال التطبيق.
          </p>

          <p class="text-subtitle-2 font-weight-bold">3. حسابات المستخدمين</p>
          <p class="text-caption ps-4">
            لاستخدام التطبيق ولوحة التحكم، قد تحتاج إلى إنشاء حساب. توافق على:
          </p>
          <v-list>
            <li class="text-caption ps-4">
              تقديم معلومات دقيقة وحديثة وكاملة أثناء عملية التسجيل.
            </li>
            <li class="text-caption ps-4">
              الحفاظ على تحديث معلومات حسابك بشكل سريع.
            </li>
            <li class="text-caption ps-4">
              الحفاظ على بيانات تسجيل الدخول الخاصة بك آمنة وسرية.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">4. طلبات الخدمة</p>
          <v-list>
            <li class="text-caption ps-4">
              يمكن للمستخدمين تصفح واختيار الخدمات المدرجة في التطبيق.
            </li>
            <li class="text-caption ps-4">
              عند الاختيار، يمكن للمستخدمين إرسال طلب إلى مقدم الخدمة.
            </li>
            <li class="text-caption ps-4">
              سيقوم مقدم الخدمة بمراجعة الطلب وقبوله أو رفضه.
            </li>
            <li class="text-caption ps-4">
              يمكن للمستخدمين التحقق من قرار مقدم الخدمة على التطبيق.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">5. المدفوعات والفواتير</p>
          <v-list>
            <li class="text-caption ps-4">
              لا تتم عمليات الدفع من خلال التطبيق.
            </li>
            <li class="text-caption ps-4">
              سيصدر مقدمو الخدمات فواتير الدفع إلى المستخدمين من خلال لوحة
              التحكم.
            </li>
            <li class="text-caption ps-4">
              سيتلقى المستخدمون الفواتير عبر البريد الإلكتروني أو وسائل الاتصال
              الأخرى المقدمة.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">6. المسؤوليات</p>
          <p class="text-caption ps-4"><strong>بالنسبة للمستخدمين:</strong></p>
          <v-list>
            <li class="text-caption ps-4">
              التأكد من أن جميع المعلومات المقدمة لمقدمي الخدمات دقيقة وكاملة.
            </li>
            <li class="text-caption ps-4">
              التواصل مباشرة مع مقدمي الخدمات بشأن تفاصيل الخدمة وترتيبات الدفع.
            </li>
          </v-list>
          <p class="text-caption ps-4">
            <strong>بالنسبة لمقدمي الخدمات:</strong>
          </p>
          <v-list>
            <li class="text-caption ps-4">الرد على طلبات الخدمة بشكل مستجد.</li>
            <li class="text-caption ps-4">
              إصدار فواتير الدفع للمستخدمين بدقة وفورية.
            </li>
            <li class="text-caption ps-4">
              تقديم الخدمات المتفق عليها للمستخدمين.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">7. تحديد المسؤولية</p>
          <v-list>
            <li class="text-caption ps-4">
              يعمل التطبيق فقط كمنصة لربط المستخدمين بمقدمي الخدمات.
            </li>
            <li class="text-caption ps-4">
              لا يتحمل التطبيق مسؤولية جودة أو سلامة أو قانونية أو تسليم الخدمات
              المقدمة.
            </li>
            <li class="text-caption ps-4">
              لا يتحمل التطبيق أية نزاعات أو مشاكل تنشأ بين المستخدمين ومقدمي
              الخدمات، بما في ذلك على سبيل الحصر للمدفوعات وتنفيذ الخدمات.
            </li>
          </v-list>

          <p class="text-subtitle-2 font-weight-bold">8. تغيير البنود</p>
          <p class="text-caption ps-4">
            نحتفظ بالحق في تعديل هذه البنود في أي وقت. سنقوم بإخطار المستخدمين
            بالتغييرات الكبيرة من خلال التطبيق أو وسائل الاتصال الأخرى. يشكل
            الاستمرار في استخدام التطبيق أو لوحة التحكم بعد إجراء التغييرات
            موافقة على البنود المحدثة.
          </p>

          <p class="text-subtitle-2 font-weight-bold">9. إنهاء الخدمة</p>
          <p class="text-caption ps-4">
            يمكننا إنهاء أو تعليق الوصول إلى التطبيق أو لوحة التحكم فوراً، دون
            إشعار مسبق أو مسؤولية، لأي سبب، بما في ذلك على سبيل الحصر انتهاك هذه
            البنود.
          </p>

          <p class="text-subtitle-2 font-weight-bold">10. القانون الساري</p>
          <p class="text-caption ps-4">
            تخضع هذه البنود للقوانين وتفسيرها وفقاً لقوانين المملكة العربية
            السعودية. أي دعوى قانونية أو إجراء ينشأ بموجب هذه البنود سيتم تقديمه
            حصرياً في المحاكم الموجودة في المملكة العربية السعودية.
          </p>

          <p class="text-subtitle-2 font-weight-bold">11. اتصل بنا</p>
          <p class="text-caption ps-4">
            إذا كان لديك أي أسئلة أو استفسارات حول هذه البنود، يرجى الاتصال بنا
            على
            <a href="mailto:support@letsevent.net">support@letsevent.net</a>.
          </p>

          <p class="text-caption ps-4">
            باستخدام تطبيق ولوحة تحكم Lets Event، تقر بأنك قد قرأت وفهمت ووافقت
            على الالتزام بهذه البنود والشروط.
          </p>
        </v-col>
      </v-row>
      <v-btn
        :text="$t('ACCEPT_TERMS_AND_CONDITIONS')"
        color="success"
        variant="tonal"
        rounded="rounded"
        width="100%"
        class="my-5"
        :to="{ name: 'login' }"
      />
    </template>
  </AuthLayout>
</template>

<script setup lang="ts"></script>
