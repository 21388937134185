// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

import { vueI18nAdapter } from '@/i18n';

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    adapter: vueI18nAdapter,
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#13263d',
          primary2: '#e3f979',
          secondary: '#5b7eb9',
          secondary2: '#faefe5',
          success: '#28a745',
          info: '#17a2b8',
          warning: '#ffc107',
          danger: '#dc3545',
          error: '#dc3545',
          gray: '#6c757d',
          olive: '#5a6c00',
          oliveLight: '#f7ffd9',
          grayLight: '#fbfbfb',
          primaryBg: '#F5F6FA',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#13263d',
          primary2: '#e3f979',
          secondary: '#5b7eb9',
          secondary2: '#faefe5',
          success: '#28a745',
          info: '#17a2b8',
          warning: '#ffc107',
          danger: '#dc3545',
          error: '#dc3545',
          gray: '#6c757d',
          olive: '#5a6c00',
          oliveLight: '#f7ffd9',
          grayLight: '#fbfbfb',
          primaryBg: '#F5F6FA',
        },
      },
    },
  },
});
